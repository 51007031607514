import {
  fetchThemeConfig,
  writeThemeConfig,
} from '@/infrastructure/apis/md/customization/jsonConfigs';
import { FEATURE, LEAD_CUSTOM_FIELDS, THEME_CONFIG_KEYS } from '@/shared/constants';
import {
  ContactFormField,
  InternalContactFormField,
  LeadCustomFieldType,
} from '@/shared/types/global';
import { LeadForm } from '@/components/LeadGen/LeadForm';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Sorter from './ContactFieldsSorter';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import {
  createLeadCustomFields,
  deleteLeadCustomFields,
  getLeadCustomFields,
  updateLeadCustomFields,
} from '@/infrastructure/apis/leadGen';
import { useAppSelector } from '@/application/hooks';
import { LeadCustomFieldAPI, LeadCustomFieldPayload } from '@/shared/types/api';
import {
  getFieldLabel,
  getShownAndNotShownFields,
  isScannableField,
  isSystemFieldEditable,
} from './utils';
import { SortableField } from './SortableField';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import { CustomMenuList, LtDialog, MenuButton } from '@/components';
import { AddOutlined, DeleteOutline, EditOutlined, MoreVert } from '@mui/icons-material';
import { AddEditCustomFieldDialog } from './AddEditCustomFieldDialog';
import { updateTranslationsJSON } from '@/infrastructure/apis/udt';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { EditSystemFieldDialog } from './EditSystemFieldDialog';
import { useUDT, useUDTByCategory } from '@/infrastructure/hooks/useUDT';
import { useDismissableAlert } from '@/infrastructure/hooks/useDismissableAlert';
import teaserImage from '@/views/images/teaser/contact-form.jpg';
import { useTranslation } from 'react-i18next';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import FeatureTeaser from '../../teaser/featureTeaser';

const CardRenderer = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <Card>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

const fetchInternalForm = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const { value } = await fetchThemeConfig<{
    fields: Array<InternalContactFormField>;
  }>(getAccessTokenSilently, THEME_CONFIG_KEYS.INTERNAL_CONTACT_FORM);

  return value;
};

export const InternalForm = () => {
  const { t } = useTranslation();
  const { isFeatureAllowed, loading } = useTierInfo();

  useDeferredLoader(loading, 'md-settings-internal-contact-form');

  if (isFeatureAllowed(FEATURE.LEAD_GEN_INTERNAL_FORM_CONFIG)) {
    return <FeatureContent />;
  } else {
    return (
      <FeatureTeaser
        text={t('upgradeTeaser.feature.leadGenInternalContactForm')}
        imageSrc={teaserImage}
      />
    );
  }
};

export const FeatureContent = () => {
  const { notify: toast } = useLtNotifications();
  const { t, activeLanguage } = useAppTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const account = useAppSelector(state => state.account);

  const [fetching, setFetching] = useState(true);
  const [formFields, setFormFields] = useState<{
    shown: InternalContactFormField[];
    notShown: InternalContactFormField[];
  }>(null);

  const [leadCustomFields, setLeadCustomFields] = useState<LeadCustomFieldAPI[]>([]);

  const [customFieldTypeToAdd, setCustomFieldTypeToAdd] = useState<LeadCustomFieldType>(null);
  const [customFieldIdToDelete, setCustomFieldIdToDelete] = useState<number>(null);

  const [customFieldIdToEdit, setCustomFieldIdToEdit] = useState<number>(null);
  const customFieldToEdit = useMemo(
    () => leadCustomFields.find(x => x.id === customFieldIdToEdit),
    [customFieldIdToEdit, leadCustomFields],
  );

  const [systemFieldToEdit, setSystemFieldToEdit] = useState<string>(null);

  const { languages, fallbackLanguage } = useUDT();

  const {
    translations: systemLabels,
    isLoading: UDTLoading,
    mutateLabels: mutateSystemLabels,
  } = useUDTByCategory('profile--system-labels');

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);
      try {
        const { fields } = await fetchInternalForm(getAccessTokenSilently);
        const leadCustomFields = await getLeadCustomFields(getAccessTokenSilently, account.id);
        setFormFields(getShownAndNotShownFields(fields, leadCustomFields));

        setLeadCustomFields(leadCustomFields);
      } catch (error) {
        //
      }
      setFetching(false);
    };

    fetchData();
  }, [getAccessTokenSilently, account.id]);

  const { config: externalContactFormConfig } = usePrivateThemeConfig<{
    fields: ContactFormField[];
  }>(THEME_CONFIG_KEYS.EXTERNAL_CONTACT_FORM);

  const isValidShownFieldsForSave = useCallback(
    (shownFields: InternalContactFormField[]) => {
      const { fields: externalContactFormFields } = externalContactFormConfig;
      if (!externalContactFormFields.every(x => shownFields.some(y => y.name === x.name))) {
        return false;
      }

      return true;
    },
    [externalContactFormConfig],
  );

  const handleSave = useCallback(
    async (newFormFields: {
      shown: InternalContactFormField[];
      notShown: InternalContactFormField[];
    }) => {
      const shownFields = newFormFields.shown;
      if (!shownFields?.length) {
        toast.error(t('contactFormEdit.atLeastOneRequired'));
        return;
      }

      if (!isValidShownFieldsForSave(shownFields)) {
        toast.error(t('contactFormEdit.usedInExternal'));
        return;
      }

      setFormFields(newFormFields);

      const payload = {
        fields: shownFields,
      };
      try {
        await writeThemeConfig(getAccessTokenSilently, {
          key: THEME_CONFIG_KEYS.INTERNAL_CONTACT_FORM,
          value: payload,
        });
        toast.success(t('changesSaved'), { id: 'contact-form-saving' });
      } catch (error) {
        toast.error(t('error.general'), { id: 'contact-form-saving' });
      }
    },
    [toast, getAccessTokenSilently, t, isValidShownFieldsForSave],
  );

  const handleSortEnd = useCallback(
    (fields: { shown: InternalContactFormField[]; notShown: InternalContactFormField[] }) => {
      handleSave(fields);
    },
    [handleSave],
  );

  const [deleting, setDeleting] = useState(false);

  const handleCustomFieldDelete = async () => {
    const newFormFields = {
      shown: formFields.shown.filter(x => x.name !== customFieldIdToDelete),
      notShown: formFields.notShown.filter(x => x.name !== customFieldIdToDelete),
    };

    if (!isValidShownFieldsForSave(newFormFields.shown)) {
      toast.error(t('contactFormEdit.deletingUsedInExternal'));
      return;
    }

    setDeleting(true);
    try {
      await deleteLeadCustomFields(getAccessTokenSilently, customFieldIdToDelete);
      await writeThemeConfig(getAccessTokenSilently, {
        key: THEME_CONFIG_KEYS.INTERNAL_CONTACT_FORM,
        value: { fields: newFormFields.shown },
      });
      setLeadCustomFields(leadCustomFields.filter(x => x.id !== customFieldIdToDelete));
      setFormFields(newFormFields);
      setCustomFieldIdToDelete(null);
    } catch (error) {
      toast.error(t('error.general'));
    }
    setDeleting(false);
  };

  const handleLeadCustomFieldAdd = async (payload: LeadCustomFieldPayload) => {
    try {
      const res = await createLeadCustomFields(getAccessTokenSilently, payload);
      setLeadCustomFields([res, ...leadCustomFields]);
      setFormFields(prev => ({
        ...prev,
        notShown: [{ name: res.id, isCustom: true }, ...prev.notShown],
      }));
      setCustomFieldTypeToAdd(null);
    } catch (error) {
      toast.error(t('error.general'));
    }
  };

  const handleLeadCustomFieldUpdate = async (payload: LeadCustomFieldPayload) => {
    try {
      const res = await updateLeadCustomFields(getAccessTokenSilently, {
        ...payload,
        id: customFieldIdToEdit,
      });

      setLeadCustomFields(leadCustomFields.map(x => (x.id === customFieldIdToEdit ? res : x)));
      setCustomFieldIdToEdit(null);
    } catch (error) {
      toast.error(t('error.general'));
    }
  };

  const handleSystemFieldUpdate = async (values: Record<string, string>) => {
    const titles = Object.entries(values).reduce(
      (acc, [lang, value]) => ({
        ...acc,
        [lang]: {
          [`attributeLabels.${systemFieldToEdit}`]: value,
        },
      }),
      {},
    );

    const payload = {
      'profile--system-labels': titles,
    };

    try {
      await updateTranslationsJSON(getAccessTokenSilently, payload);

      mutateSystemLabels({
        ...systemLabels,
        [`attributeLabels.${systemFieldToEdit}`]: values,
      });

      setSystemFieldToEdit(null);
    } catch (error) {
      toast.error(t('error.general'));
    }
  };

  const getTranslatedFieldLabel = useCallback(
    (field: InternalContactFormField) => {
      return getFieldLabel(
        field,
        leadCustomFields,
        t,
        systemLabels,
        activeLanguage,
        fallbackLanguage,
      );
    },
    [leadCustomFields, t, activeLanguage, systemLabels, fallbackLanguage],
  );

  const renderSortableItem = useCallback(
    (field: InternalContactFormField) => {
      const isEditable = field.isCustom || isSystemFieldEditable(field.name as string);
      const isDeletable = field.isCustom;
      const isScannable = isScannableField(field.name as string);
      const chips = [field.isCustom && t('custom'), isScannable && t('scannable')].filter(Boolean);

      const title = getTranslatedFieldLabel(field);

      const menuActions = [
        isEditable && {
          icon: <EditOutlined />,
          label: t('contactFormEdit.internal.editField'),
          onClick: () =>
            field.isCustom
              ? setCustomFieldIdToEdit(field.name as number)
              : setSystemFieldToEdit(field.name as string),
        },
        isDeletable && {
          icon: <DeleteOutline />,
          label: t('contactFormEdit.internal.deleteField'),
          onClick: () => setCustomFieldIdToDelete(field.name as number),
        },
      ].filter(Boolean);

      return (
        <SortableField
          title={title}
          key={field.name}
          chips={chips}
          action={
            Boolean(menuActions?.length) && (
              <CustomMenuList
                items={menuActions}
                toggleElement={
                  <IconButton
                    aria-label={t('ariaOpenActionFor', { name: title })}
                    aria-haspopup='listbox'
                  >
                    {<MoreVert />}
                  </IconButton>
                }
              />
            )
          }
        />
      );
    },
    [getTranslatedFieldLabel, t],
  );

  const menuButtonActions = Object.values(LEAD_CUSTOM_FIELDS).map(field => ({
    actionText: t(`contactFormEdit.internal.customFields.${field}.title`),
    tooltipText: t(`contactFormEdit.internal.customFields.${field}.tooltip`),
    onClick: () => setCustomFieldTypeToAdd(field),
  }));

  const { AlertElement } = useDismissableAlert({
    title: t('contactFormEdit.internal.alert.title'),
    body: t('contactFormEdit.internal.alert.body'),
    key: 'contact-form-edit-alert',
  });

  if (fetching || UDTLoading) return null;

  return (
    <Box>
      {AlertElement}
      <Grid container spacing={1.6} mt={AlertElement && 3}>
        <Grid item xs={12} xl={8}>
          <CardRenderer>
            <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
              <Typography sx={{ mb: 1.6 }} variant='h2'>
                {t('contactFormEdit.title')}
              </Typography>
              <MenuButton
                variant='contained'
                color='primaryButton'
                label={t('Add Field')}
                aria-label={t('ariaExportSelected')}
                actionGroups={[
                  {
                    actions: menuButtonActions,
                  },
                ]}
                icon={<AddOutlined />}
              />
            </Box>
            {formFields && (
              <Sorter
                fields={formFields}
                onSortEnd={handleSortEnd}
                renderItem={renderSortableItem}
                getName={getTranslatedFieldLabel}
              />
            )}
          </CardRenderer>
        </Grid>
        <Grid item xs={6} xl={4}>
          <Card>
            <CardHeader
              sx={theme => ({ bgcolor: theme.palette.action.hover })}
              title={t('livePreview')}
              titleTypographyProps={{
                component: 'h3',
                variant: 'h2',
                color: 'secondary',
              }}
            />
            <Divider />
            <Box p={2}>
              <LeadForm leadCustomFields={leadCustomFields} fields={formFields.shown} isPreview />
            </Box>
          </Card>
        </Grid>
      </Grid>
      {/* Create custom field */}
      <AddEditCustomFieldDialog
        open={Boolean(customFieldTypeToAdd)}
        fieldType={customFieldTypeToAdd}
        onClose={() => setCustomFieldTypeToAdd(null)}
        onSave={handleLeadCustomFieldAdd}
        languages={languages}
      />
      {/* Edit custom field */}
      <AddEditCustomFieldDialog
        initialValues={customFieldToEdit}
        open={Boolean(customFieldToEdit)}
        fieldType={customFieldToEdit?.type}
        onClose={() => setCustomFieldIdToEdit(null)}
        onSave={handleLeadCustomFieldUpdate}
        languages={languages}
      />
      {/* Edit system field */}
      <EditSystemFieldDialog
        open={Boolean(systemFieldToEdit)}
        fieldKey={systemFieldToEdit}
        onClose={() => setSystemFieldToEdit(null)}
        onSave={handleSystemFieldUpdate}
        initialValues={systemLabels[`attributeLabels.${systemFieldToEdit}`]}
        languages={languages}
      />

      <LtDialog
        open={Boolean(customFieldIdToDelete)}
        onClose={() => setCustomFieldIdToDelete(null)}
        onCancel={() => setCustomFieldIdToDelete(null)}
        title={t('contactFormEdit.internal.deletePopupTitle')}
        size='sm'
        deleteAction={{
          onClick: handleCustomFieldDelete,
          loading: deleting,
        }}
        withActionDivider
      >
        <Typography>{t('contactFormEdit.internal.deletePopupBody')}</Typography>
      </LtDialog>
    </Box>
  );
};
