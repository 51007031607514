import { FeatureSet } from '../../types/api';

export const THEME_LEADS_DEFAULT_PAGE_SIZE = 20;

export const FEATURE = {
  LEAD_GEN_INDIVIDUAL: 'leadGenIndividual' as keyof FeatureSet,
  LEAD_GEN_MD: 'leadGenMd' as keyof FeatureSet,
  LEAD_GEN_FORM_CONFIG: 'leadGenFormConfig' as keyof FeatureSet,
  UNITS: 'units' as keyof FeatureSet,
  BLOCKED_BIO_FIELDS: 'blockedBioFields' as keyof FeatureSet,
  LINK_TYPE_CONFIG: 'linkTypeConfig' as keyof FeatureSet,
  INTERNAL_DESIGN: 'internalDesign' as keyof FeatureSet,
  DATA_TEMPLATES: 'dataTemplates' as keyof FeatureSet,
  EMAIL_TEMPLATES: 'emailTemplates' as keyof FeatureSet,
  GLOBAL_DATA: 'globalData' as keyof FeatureSet,
  ANALYTICS_INDIVIDUAL: 'analyticsIndividual' as keyof FeatureSet,
  ANALYTICS_MD: 'analyticsMd' as keyof FeatureSet,
  BULK_EXPORT: 'bulkExport' as keyof FeatureSet,
  BULK_CSV_ACTIONS: 'bulkCsvActions' as keyof FeatureSet,
  EDIT_RIGHTS: 'editRights' as keyof FeatureSet,
  USER_DEFINED_TRANSLATIONS: 'userDefinedTranslations' as keyof FeatureSet,
  MULTI_ADMIN: 'multiAdmin' as keyof FeatureSet,
  PROFILE_SYNC: 'profileSync' as keyof FeatureSet,
  SSO: 'sso' as keyof FeatureSet,
  UNIT_HIERARCHIES_EDIT_RIGHTS: 'unitHierarchies_editRights' as keyof FeatureSet,
  UNIT_HIERARCHIES_PROFILE_DESIGN: 'unitHierarchies_profileDesign' as keyof FeatureSet,
  BUSINESS_CARD_SCANNER: 'businessCardScanner' as keyof FeatureSet,
  EMAIL_EDITOR_FULL: 'emailEditorFull' as keyof FeatureSet,
  CRM_INTEGRATION: 'crmIntegration' as keyof FeatureSet,
  ACADEMY: 'academy' as keyof FeatureSet,
  CUSTOM_DOMAIN: 'customDomain' as keyof FeatureSet,
  VCARD_CONFIG: 'vcardConfig' as keyof FeatureSet,
  VISIBILITY_CONFIG: 'visibilityConfig' as keyof FeatureSet,
  LEAD_GEN_INTERNAL_FORM_CONFIG: 'leadGenInternalFormConfig' as keyof FeatureSet,
} as const;
export type Feature = keyof FeatureSet;

export const USERS_IMPORT_LIMIT = 20;

export enum EmailTemplatesEnum {
  CONTACT_SHARE_EMAIL_TEMPLATE = 'contact-share-email-template',
  GENERIC_STATUS_EMAIL = 'generic-status-email',
  QR_BG_COMPLETE_EMAIL_TEMPLATE = 'qr-bg-complete-email-template',
  QR_COMPLETE_EMAIL_TEMPLATE = 'qr-complete-email-template',
  CONTACT_RECEIVED_EMAIL_TEMPLATE = 'contact-received-email-template',
  WELCOME_EMAIL_TEMPLATE = 'welcome-email-template',
  CARD_ORDER_CONFIRMATION_EMAIL_TEMPLATE = 'card-order-confirmation-email-template',
  REFERRAL_EMAIL_TEMPLATE = 'referral-email-template',
  REFERRAL_REFERRER_TEMPLATE = 'referral-referrer-template',
  REFERRAL_RECEPIENT_TEMPLATE = 'referral-recipient-template',
  WALLET_PASS_MAIL_TEMPLATE = 'wallet-pass-mail-template',
  ONBOARDING_EMAIL_TEMPLATE = 'onboarding-email-template',
}

export enum EmailTemplateAttachments {
  OFFLINE_APPLE_WALLET = 'offline-apple-wallet',
  ONLINE_APPLE_WALLET = 'online-apple-wallet',
}

export enum THEME_CONFIG_KEYS {
  QR_BG_IMAGES_MOBILE = 'qr-bg-images-mobile',
  QR_BG_IMAGES_VIDEOCALL = 'qr-bg-images-videocall',
  QR_BG_CONFIG = 'qr-bg-config',
  EXTERNAL_CONTACT_FORM = 'external-contact-form',
  INTERNAL_CONTACT_FORM = 'internal-contact-form',
  BLOCKED_BIO_FIELDS = 'blocked-bio-fields',
  BLOCKED_LINK_TYPES = 'blocked-link-types',
  MD_GUIDE = 'md-guide',
  MD_NFC_CARDS_PAGE = 'md-nfc-cards-page',
  ACCOUNT_DEFAULTS = 'account-attribute-defaults',
  EMAIL_DEFAULT_FOOTER = 'email-default-footer',
  GOOGLE_WALLET_PASS_CLASS_ID = 'google-wallet-pass-class-id',
  WALLET_CARD_COLORS = 'wallet-card-colors',
  EDIT_RIGHTS = 'edit-rights',
  PROFILE_DESIGN = 'profile-design',
  INTERNAL_DESIGN = 'internal-design',
  OVERRIDE_SETTINGS = 'override-settings',
  FEATURE_FLAGS_PRIVATE = 'feature-flags-private',
  FEATURE_FLAGS_PUBLIC = 'feature-flags-public',
  HELP_RESOURCE_URLS = 'help-resource-urls',
  ALLOWED_EMAIL_TEMPLATES_IN_EDITOR = 'allowed-email-templates-in-editor',
  USER_DEFINED_TRANSLATION_PROFILE__LINKS = 'user-defined-translation-profile--links',
  USER_DEFINED_TRANSLATION_PROFILE__FILES = 'user-defined-translation-profile--files',
  USER_DEFINED_TRANSLATION_PROFILE__SYSTEM_LABELS = 'user-defined-translation-profile--system-labels',
  USER_DEFINED_TRANSLATION_PROFILE__POSITION = 'user-defined-translation-profile--position',
  USER_DEFINED_TRANSLATION_PROFILE__ROLE = 'user-defined-translation-profile--role',
  USER_DEFINED_TRANSLATION_PROFILE__DIVISION = 'user-defined-translation-profile--division',
  USER_DEFINED_TRANSLATION_PROFILE__BIO = 'user-defined-translation-profile--bio',
  USER_DEFINED_TRANSLATION_PROFILE__ADDRESSES = 'user-defined-translation-profile--addresses',
  USER_DEFINED_TRANSLATION_PROFILE__FIRSTNAME = 'user-defined-translation-profile--firstName',
  USER_DEFINED_TRANSLATION_PROFILE__LASTNAME = 'user-defined-translation-profile--lastName',
  USER_DEFINED_TRANSLATION_PROFILE__COMPANY = 'user-defined-translation-profile--company',
  USER_DEFINED_TRANSLATION_PROFILE__HOMEPAGE = 'user-defined-translation-profile--homepage',
  USER_DEFINED_TRANSLATION_PROFILE__OTHER_CONTACT_INFO = 'user-defined-translation-profile--other-contact-info',
  USER_DEFINED_TRANSLATION_PROFILE__URLS = 'user-defined-translation-profile--urls',
  USER_SETTINGS_CONFIG = 'user-settings-config',
  USER_MORE_SETTINGS_CONFIG = 'user-more-settings-config',
  GDPR_LEGAL_BASIS_OPTIONS = 'gdpr-legal-basis-options',
  HELP_SIDEBAR_CRM_INTEGRATION = 'help-sidebar-crm-integration',
  HELP_SIDEBAR_TRANSLATIONS = 'help-sidebar-translations',
  HELP_SIDEBAR_EMAIL_EDITOR = 'help-sidebar-email-editor',
  HELP_SIDEBAR_UNITS = 'help-sidebar-units',
  HELP_SIDEBAR_IDP_LIST = 'help-sidebar-idp-list',
  HELP_SIDEBAR_CONFIGURED_IDP_LIST = 'help-sidebar-configured-idp-list',
  HELP_SIDEBAR_IDP_SETTINGS = 'help-sidebar-idp-settings',
  HELP_SIDEBAR_SSO_SETTINGS = 'help-sidebar-sso-settings',
  EMAIL_EDITOR_FIXED_CONTENT = 'email-editor-fixed-content',
  ADMIN_CARD_ORDER_GOOGLE_FORM = 'admin-card-order-google-form',
  HOMEPAGE_GUIDE_BOX = 'homepage-guide-box',
  QUOTA_BUSINESS_CARD_SCANS = 'quota-business-card-scans',
  BEPRINT_CONFIG = 'beprint-config',
  VCARD_CONFIG = 'vcard-config',
  LEAD_EMAIL_EXPORT = 'lead-email-export',
  VISIBILITY_CONFIG = 'visibility-config',
  CRM_ERROR_HANDLER_CONFIG = 'crm-error-handler-config',
}

export type QuotaKey = {
  [K in THEME_CONFIG_KEYS]: K extends `quota-${string}` ? `${K}` : never;
}[THEME_CONFIG_KEYS];

export type HelpSidebarKey = {
  [K in THEME_CONFIG_KEYS]: K extends `help-sidebar-${string}` ? `${K}` : never;
}[THEME_CONFIG_KEYS];
